import React, { useState } from "react";
import { PaystackButton } from "react-paystack";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logomv from "../Images/logooo.png";
import "../Payment/payment.css";

const Index = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [paystackConfig, setPaystackConfig] = useState(null);

  const publicKey = "pk_live_dcc80064000926885f8d5142f3137015f9f2d8f5";

  const handleFormSubmit = (e) => {
    e.preventDefault();

    toast.info("Payment processing...");

    // Set Paystack configuration
    setPaystackConfig({
      email,
      amount: amount * 100, // Paystack expects the amount in kobo
      currency: "NGN",
      publicKey,
      text: "Proceed to Payment",
      onSuccess: () =>
        toast.success("Payment was successful!", {
          position: "top-right",
        }), // Notify user on success
      onClose: () =>
        toast.info("Transaction was not completed, window closed."),
    });
  };

  return (
    <div>
      <img src={Logomv} alt="logo" className="log" />

      <section className="payment-container">
        <p className="investment">Payment Information</p>
        <p className="fill">Please fill in this information</p>

        <form onSubmit={handleFormSubmit} id="investorForm">
          <div>
            <label htmlFor="name" style={{ marginRight: "1.5rem" }}>
              Name:
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <br />
          <div>
            <label htmlFor="email" style={{ marginRight: "1.5rem" }}>
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <br />
          <div>
            <label htmlFor="Amount" style={{ marginRight: "1.5rem" }}>
              Amount Paid For Shares (in Naira equivalent):
            </label>
            <input
              type="number"
              id="amount"
              name="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </div>
          <br />
          <button type="submit" className="pay">
            Pay
          </button>
        </form>
        {paystackConfig && (
          <PaystackButton {...paystackConfig} className="pay" />
        )}
        <p className="payment-text">
          Send these informations so that we can process your payment for
          HospiYou, inc
        </p>
      </section>

      {/* Toastify container */}
      <ToastContainer />
    </div>
  );
};

export default Index;
