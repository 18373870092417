import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import Payment from "./Payment";
import Paystackpay from "../src/Paystack";
import About from "../src/About";
import Success from "../src/Stripe/Success";
import StripePay from "../src/Stripe/PaymentForm";
import NutritionForm from "../src/Gift/NutritionForm";
import "./App.css";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/payment" element={<Payment />} />
      <Route path="/paystack-payment" element={<Paystackpay />} />
      <Route path="/about" element={<About />} />
      <Route path="/nutrition-form" element={<NutritionForm />} />

      <Route path="/stripe-payment" element={<StripePay />} />
      <Route path="/success" element={<Success />} />
    </Routes>
  );
};

export default App;
