import React from "react";
import ScrollAnimation from "../ScrollAnimation";
import { fadeInLeft } from "../animationVariants";
import Vision from "../Images/vision.png";
import Visionmv from "../Images/vision-mv.png";
import "./vision.css";

const index = () => {
  return (
    <ScrollAnimation animationVariants={fadeInLeft}>
      <div className="container">
        <section className="vision">
          <div>
            <p className="s-text vision-w" style={{ color: "green" }}>
              What we want to do
            </p>
            <h3>Our Vision</h3>
          </div>
          <p>
            At Hospiyou, we believe that healthcare is a fundamental right, not
            a privilege. Our vision is to bridge the gap between patients and
            healthcare providers, revolutionizing healthcare delivery worldwide.
          </p>
        </section>
        <img src={Vision} alt="vision" className="visionimg-dt" />
        <img src={Visionmv} alt="vision" className="visionimg-mv" />
      </div>
    </ScrollAnimation>
  );
};

export default index;
