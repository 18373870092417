import React from "react";
import { Link } from "react-router-dom";
import { scrollToComponent } from "../scrollToComponent";
import ScrollAnimation from "../ScrollAnimation";
import { fadeInUp } from "../animationVariants";
import Logo from "../Images/logooo.png";
import Logomv from "../Images/logo-MV.png";
import Videologo from "../Images/carbon_play-filled.svg";
import Verline from "../Images/verLine.png";
// import Hamb from "../Images/hamb.png";
import "./Hero.css";

const index = () => {
  return (
    <ScrollAnimation animationVariants={fadeInUp}>
      {/* Navigations */}
      <section className="Hero-Section">
        <Link to="/">
          <img src={Logomv} alt="logo" className="mv-logo" />
        </Link>
        <Link to="/">
          <img src={Logo} alt="logo" className="dt-logo" />
        </Link>

        <div className="reverse">
          <Link className="invest" to="/payment">
            Invest Now
          </Link>

          <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li onClick={() => scrollToComponent("about")}>About</li>
              <li onClick={() => scrollToComponent("invest")}>Invest</li>
              <li onClick={() => scrollToComponent("contact")}>Contact</li>
              <li onClick={() => scrollToComponent("team")}>Team</li>
              <li>FAQ</li>
            </ul>
          </nav>

          {/* <img src={Hamb} alt="hamburger menu" className="hamb" /> */}
        </div>
      </section>

      {/* Hero Text and Image */}
      <section className="hero-section">
        <h2 className="hero-text">
          Have a Share of Global Healthcare Revolution
        </h2>
        <p className="sub-text_dt">
          We are on a mission to transform the way healthcare is delivered
          globally, making it accessible, affordable, and efficient for
          everyone. Join us in this groundbreaking journey by becoming an
          investor in Hospiyou through our equity crowdfunding campaign
        </p>
        <p className="sub-text_mv">Become an investor in Hospiyou</p>

        <div className="link">
          <Link className="invest" to="/payment">
            Invest Now
          </Link>
          <a
            href="https://youtube.com/shorts/zkI1z7C4tO8?si=QZ5u9bIp1G1lpc1Q"
            className="placement"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Videologo} alt="watch" srcset="" />
            <p className="watch" style={{ display: "inline" }}>
              Watch video
            </p>
          </a>
        </div>

        <div className="stats">
          <div>
            <p className="m-text">$20,000</p>
            <p className="s-text">Of $2,500,000</p>
          </div>
          <img src={Verline} height="60px" alt="" srcset="" />
          <div>
            <p className="m-text">100</p>
            <p className="s-text">Investors</p>
          </div>
          <img src={Verline} height="60px" alt="" srcset="" />
          <div>
            <p className="m-text">70</p>
            <p className="s-text">Days Left</p>
          </div>
        </div>
      </section>
    </ScrollAnimation>
  );
};

export default index;
