import React from "react";
import "./why-invest.css";

const index = () => {
  return (
    <div className="why">
      <div style={{ textAlign: "center" }} className="w-invest">
        <p className="s-text about-w" style={{ color: "green" }}>
          What we want to do
        </p>
        <h3>Why you should invest in us</h3>
      </div>

      {/* The box */}
      <div className="contain">
        <div className="plan-boxes">
          <p className="number">01</p>
          <p className="stext ">Market Potential</p>
          <p>
            The global telemedicine market is booming, projected to grow at a
            CAGR of 13.88% from 2023-2028. We are wee positioned to capitalize
            on this trend
          </p>
        </div>
        <div className="plan-boxes" style={{ borderTop: "3px solid #0f8fe6" }}>
          <p className="number">02</p>
          <p className="stext ">Innovative Solutions</p>
          <p>
            We offer a comprehensive suite of telemedicine solutions powered by
            AI including in-app diagnostic test, remote consultation ,nursing
            care services, wearable, medical consumables, Hospiyou for medical
            facilities, ambulance Saas and innovative medical robots
          </p>
        </div>
        <div className="plan-boxes">
          <p className="number">03</p>
          <p className="stext ">Experienced Leadership</p>
          <p>
            Led by Godswill Njoku, our leadership team comprises professionals
            with expertise in healthcare, technology and business management
          </p>
        </div>
        <div className="plan-boxes">
          <p className="number">04</p>
          <p className="stext ">Social Impact</p>
          <p>
            By investing in Hospiyou, you're not jus investing in a company
            you're investing in a better future for healthcare particularly to
            understand markets like Africa and Asia
          </p>
        </div>
      </div>
    </div>
  );
};

export default index;
