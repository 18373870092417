import React from "react";
import "./thanks.css";

const index = () => {
  return (
    <div>
      <div className="wrap">
        <h3>Thank you!</h3>
        <p>Sign up for our news letter</p>

        <div>
          <input
            type="email"
            name="email"
            id=""
            className="email"
            placeholder="Enter your Email"
          />
          <input type="button" value="Subscribe" className="investTh" />
        </div>
      </div>
    </div>
  );
};

export default index;
