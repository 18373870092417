import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./PaymentForm.css";

const stripePromise = loadStripe(
  "pk_live_51MlJ3tABSGfAGZFGLFx2WRWstbPWHfDdcPRMj10sBd09VIxvfQxKvcGuUkjYEpTSbXam4R2SuTwuG1VAwOiwHVx0001nAIWbVy"
);

const CheckoutForm = () => {
  const [amount, setAmount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.log("Stripe or Elements not loaded");
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        console.error("Error creating payment method:", error);
        setErrorMessage(error.message);
        return;
      }

      const response = await axios.post(
        "https://hospiyoupayments.onrender.com⁠/stripe/create-payment-intent",
        {
          amount: amount * 100, // Amount in cents
          payment_method: paymentMethod.id,
          return_url: "https://hospiyou-investors.com/success",
        }
      );

      const { clientSecret, status } = response.data;

      if (status === "requires_action") {
        const confirmResult = await stripe.confirmCardPayment(clientSecret, {
          return_url: "https://hospiyou-investors.com/success",
        });
        if (confirmResult.error) {
          console.error("Error confirming card payment:", confirmResult.error);
          setErrorMessage(confirmResult.error.message);
        } else {
          if (confirmResult.paymentIntent.status === "succeeded") {
            console.log("Payment successful");
            showToastAndNavigate();
          } else {
            setErrorMessage("Payment failed, please try again.");
          }
        }
      } else if (status === "succeeded") {
        console.log("Payment successful");
        showToastAndNavigate();
      } else {
        setErrorMessage("Payment failed, please try again.");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Axios error:", error.response?.data || error.message);
        setErrorMessage(error.response?.data?.error || error.message);
      } else {
        console.error("Unexpected error:", error);
        setErrorMessage("An unexpected error occurred, please try again.");
      }
    }
  };

  const showToastAndNavigate = () => {
    toast.success("Payment successful!", {
      position: "top-center",
      autoClose: 3000,
      onClose: () => navigate("/"),
    });
  };

  return (
    <div className="stripe-cont">
      <ToastContainer />
      <div className="form-container">
        <h2>Stripe Payment</h2>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="amount">Amount:</label>
            <input
              type="number"
              id="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
              min="1"
            />
          </div>
          <div className="form-group card-element">
            <CardElement />
          </div>
          <div className="button-container">
            <button type="submit" disabled={!stripe}>
              Pay
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const PaymentForm = () => (
  <Elements stripe={stripePromise}>
    <CheckoutForm />
  </Elements>
);

export default PaymentForm;
