import React from "react";
import Hero from "../Hero-Section";
import Invest from "../Investing";
import About from "../About";
import Vision from "../Vision";
import Why from "../Why-invest";
import How from "../How";
import Team from "../Team/Index";
import Comments from "../Comments";
import Thanks from "../Thanks";
import Footer from "../Footer";
// import Stripe from "../Stripe/PaymentForm";

const index = () => {
  return (
    <div>
      <Hero />
      <Invest />
      <About />
      <Vision />
      <Why />
      <How />
      <Team />
      <Comments />
      <Thanks />
      <Footer />
      {/* <Stripe /> */}
    </div>
  );
};

export default index;
