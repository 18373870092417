import React from "react";
import ScrollAnimation from "../ScrollAnimation";
import { fadeInRight } from "../animationVariants";
import "./About.css";

const index = () => {
  return (
    <ScrollAnimation animationVariants={fadeInRight} id="about">
      <section className="about">
        <div>
          <p className="s-text about-w" style={{ color: "green" }}>
            Who we are
          </p>
          <h3>About Project</h3>
        </div>
        <p>
          Hospiyou is an AI powered global telemedical corporation that assists
          people get medically tested and diagnosed from anywhere and at
          anytime. Hospiyou serves as a middle man between patients and Medical
          facilities (Hospitals, Pharmacies, Laboratories, ect, an emergency
          response ambulance system), and as a system that helps to facilitate
          medical services.
        </p>
      </section>
    </ScrollAnimation>
  );
};

export default index;
