import React from "react";
import "./team.css";
import Group from "../Images/grp-team.png";
import Plus from "../Images/plus.png";
import Njoku from "../Images/Will.png";
import Armon from "../Images/Armon.png";
import Ifeanyi from "../Images/Ifeanyi.png";
import Troy from "../Images/Troy.png";
import Akomas from "../Images/Akomas.png";
import Allwell from "../Images/Allwell.png";
import Vianney from "../Images/vian.png";
import Ola from "../Images/Olaleye.png";
import Favour from "../Images/Favour.png";
import Ibiso from "../Images/ibiso.png";
import Isosiya from "../Images/davis.png";
import Philip from "../Images/philip.png";
import Timi from "../Images/timi.png";
import Blessing from "../Images/blessing.png";
import Omotayo from "../Images/omotayo.png";

const Index = () => {
  return (
    <div className="team-container" id="team">
      <div className="team">
        <h2>Our Team</h2>
        <p>Meet the great team behind hospiyou</p>
      </div>
      <div className="conta">
        <div className="img-cont">
          <div className="grp-imgs">
            <img src={Group} alt="" />
            <img src={Plus} alt="" />
          </div>
        </div>
        <div className="team-cont">
          <div className="cont">
            <img src={Njoku} alt="" />
            <div>
              <p>Godswill</p>
              <p>CEO & Founder</p>
            </div>
          </div>
          <div className="cont">
            <img src={Akomas} alt="" />
            <div>
              <p>Akomas Ifeanyi</p>
              <p>CTO/Cofounder</p>
            </div>
          </div>
          <div className="cont">
            <img src={Armon} alt="" />
            <div>
              <p>Armon Rabie</p>
              <p>Medicine</p>
            </div>
          </div>
          <div className="cont">
            <img src={Troy} alt="" />
            <div>
              <p>Troy At</p>
              <p>Dentistry</p>
            </div>
          </div>
          <div className="cont">
            <img src={Ifeanyi} alt="" />
            <div>
              <p>Ifeanyi Peters</p>
              <p>Doctor</p>
            </div>
          </div>
          <div className="cont">
            <img src={Allwell} alt="" />
            <div>
              <p>Allwell Ihejirika</p>
              <p>CFO</p>
            </div>
          </div>
          <div className="cont">
            <img src={Vianney} alt="" />
            <div>
              <p>Vianney Chiamaka</p>
              <p>Medicine</p>
            </div>
          </div>
          <div className="cont">
            <img src={Ola} alt="" />
            <div>
              <p>Dr Olaleye Ahmed</p>
              <p>Doctor</p>
            </div>
          </div>
          <div className="cont">
            <img src={Favour} alt="" />
            <div>
              <p>Peters Ledum Favour</p>
              <p>Software Developer</p>
            </div>
          </div>
          <div className="cont">
            <img src={Ibiso} alt="" />
            <div>
              <p>Ibiso Benson-Taylor</p>
              <p>Nurse</p>
            </div>
          </div>
          <div className="cont">
            <img src={Isosiya} alt="" />
            <div>
              <p>Isosiya Davies</p>
              <p>Pharmacology</p>
            </div>
          </div>
          <div className="cont">
            <img src={Philip} alt="" />
            <div>
              <p>Philip Ogbeye</p>
              <p>Pharmacy/COO</p>
            </div>
          </div>
          <div className="cont">
            <img src={Timi} alt="" />
            <div>
              <p>Miebi Timi</p>
              <p>Nurse</p>
            </div>
          </div>
          <div className="cont">
            <img src={Blessing} alt="" />
            <div>
              <p>Blessing Idiong</p>
              <p>Optometry</p>
            </div>
          </div>
          <div className="cont">
            <img src={Omotayo} alt="" />
            <div>
              <p>Omotayo Tomiwa</p>
              <p>CPO/Public Health</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
