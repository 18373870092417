import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import ScrollAnimation from "../ScrollAnimation";
import { fadeInUp } from "../animationVariants";
import Logo from "../Images/logooo.png";
import Logomv from "../Images/logo-MV.png";
import "./footer.css";
const index = () => {
  return (
    <ScrollAnimation animationVariants={fadeInUp}>
      <div className="grid-container">
        <div className="hos">
          <img src={Logomv} alt="logo" className="mv-logo" />
          <img src={Logo} alt="logo" className="dt-logo" />
          <p>
            Be a part of the healthcare revolution with Hospiyou. Invest in our
            equity crowdfunding campaign and help us make quality healthcare
            accessible to everyone anywhere and anytime
          </p>

          <a
            href="https://www.instagram.com/hospiyou/?igshid=YmMyMTA2M2Y%3D"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              className="icon"
              icon={faInstagram}
              size="2x"
              color="#0f8fe6"
            />
          </a>

          <a
            href="https://x.com/HospiYou?mx=2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              className="icon"
              icon={faTwitter}
              size="2x"
              color="#0f8fe6"
            />
          </a>

          <a
            href="https://www.youtube.com/@HospiYou?feature=shareb"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              className="icon"
              icon={faYoutube}
              size="2x"
              color="#0f8fe6"
            />
          </a>

          <a
            href="https://web.facebook.com/people/HospiYou/61556145362387/?mibextid=2JQ9oc"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              className="icon"
              icon={faFacebook}
              size="2x"
              color="#0f8fe6"
            />
          </a>
        </div>
        <div className="GIT">
          <h3>Get in Touch</h3>
          <p>Investment Opportunity</p>
          <p>How it works</p>
        </div>
        <div className="learn-more">
          <h3>Learn more</h3>
          <p>About Us</p>
          <p>Testimonials</p>
        </div>
        <div className="contact">
          <h3>Contact us</h3>
          <p>+35987700582, +2347037432078</p>
          <p>hospiyou@gmail.com</p>
          <p>Terms of Services</p>
        </div>
      </div>
    </ScrollAnimation>
  );
};

export default index;
