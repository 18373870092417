import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "../ScrollAnimation";
import { fadeInUp } from "../animationVariants";
import "./Invest.css";

const Index = () => {
  return (
    <ScrollAnimation animationVariants={fadeInUp} id="invest">
      <section className="progress">
        <p className="s-text">Check out our progress</p>

        <div className="bar">
          <div className="bar-progress"></div>
        </div>

        <div className="rate s-text">
          <p>50%</p>
          <p>$2,500,000</p>
        </div>

        <div className="plans">
          <div className="plans-box">
            <p className="l-text plans-box_head">Investing Less than $1000</p>
            <ul>
              <li>
                Hospiyou inc equity, shares percentage, ownership certificates
              </li>
              <li>20% off consultations per 6 months with voucher code</li>
              <li>Access to monthly shareholders update from company</li>
            </ul>
          </div>

          <div className="plans-box">
            <p className="l-text plans-box_head">Investing Above $1000</p>
            <ul>
              <li>
                Hospiyou inc equity, shares percentage, ownership certificates
              </li>
              <li>50% off consultations per 6 months with voucher code</li>
              <li>Access to monthly shareholders update from company</li>
              <li>Access to monthly shareholders update from company</li>
            </ul>
          </div>
          <div className="center">
            <div>
              <p className="m-text">$20,000</p>
              <p className="s-text">Pledged at $2,500,000</p>
            </div>
            <div>
              <p className="m-text">$20</p>
              <p className="s-text">Minimum Investment</p>
            </div>
            <div>
              <p className="m-text">100</p>
              <p className="s-text">Investors</p>
            </div>
            <div>
              <p className="m-text">70</p>
              <p className="s-text">Days to go</p>
            </div>
            <div>
              <p className="m-text">$10M</p>
              <p className="s-text">Valuation</p>
            </div>
            <Link className="invest" to="/payment">
              Invest Now
            </Link>
          </div>
        </div>
      </section>
    </ScrollAnimation>
  );
};

export default Index;
