import React from "react";
import ScrollAnimation from "../ScrollAnimation";
import { fadeInUp } from "../animationVariants";
import Jess from "../Images/jessica.png";
import Antho from "../Images/anthony.png";
import Star from "../Images/stars.png";
import "./comments.css";

const index = () => {
  return (
    <ScrollAnimation animationVariants={fadeInUp}>
      <div className="comment">
        <section className="review">
          <img src={Jess} alt="" />
          <p className="s-text">
            I took the leap and invested in Hospiyou equity campaign and i cant
            be happier. Their dedication to transforming healthcare is evident
            in every detail. Dont miss this!
          </p>

          <div className="ratings">
            <p>Jessica Peters</p>
            <img src={Star} alt="star-rating" style={{ display: "inline" }} />
          </div>
        </section>
        <section className="review review-two">
          <img src={Antho} alt="" />
          <p className="s-text">
            With a clear vision and team of experts during the mission forward,
            i am confident in the impact they will make. Join me in supporting
            their journey
          </p>

          <div className="ratings">
            <p>Anthony Smith</p>
            <img src={Star} alt="star-rating" style={{ display: "inline" }} />
          </div>
        </section>
      </div>
    </ScrollAnimation>
  );
};

export default index;
