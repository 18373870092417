import React, { useState } from "react";
import axios from "axios";
import Logomv from "../Images/logooo.png";
import Paystack from "../Images/Paystack.svg";
import Stripe from "../Images/stripe-seeklogo.svg";
import "./payment.css";
import { Link } from "react-router-dom";

const Index = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
  });
  const [loading, setLoading] = useState(false);

  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setLoading(true); // Start loading

    try {
      const response = await axios.post(
        "https://hospiyoupayments.onrender.com/store_investor_data",
        formData
      );
      setResponseMessage(response.data.message);
    } catch (error) {
      setResponseMessage(error.response?.data?.error || "An error occurred");
    } finally {
      setLoading(false); // Stop loading
    }

    const gateway = document.querySelector(".Choose-Payment");
    gateway.style.display = "block";
  };

  return (
    <div>
      <img src={Logomv} alt="logo" className="log" />

      <section className="payment-container">
        <p className="investment">Investor Information</p>
        <p className="fill">Please fill in these information</p>

        {responseMessage && (
          <div className="response-message">{responseMessage}</div>
        )}

        {loading ? (
          <div className="loader">Loading...</div>
        ) : (
          <form onSubmit={handleFormSubmit} id="investorForm">
            <div>
              <label htmlFor="name" style={{ marginRight: "1.5rem" }}>
                Name:
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <br />
            <div>
              <label htmlFor="email" style={{ marginRight: "1.5rem" }}>
                Email:
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <br />
            <div>
              <label htmlFor="phone" style={{ marginRight: "1.5rem" }}>
                Phone Number:
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <br />
            <div>
              <label htmlFor="address" style={{ marginRight: "1.5rem" }}>
                Home Address:
              </label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              />
            </div>
            <br />
            <div>
              <label htmlFor="date" style={{ marginRight: "1.5rem" }}>
                Date of intended Purchase:
              </label>
              <input type="date" />
            </div>
            <button type="submit" className="pay">
              Proceed to Payment
            </button>
          </form>
        )}
        {/* choose payment method */}
        <div className="Choose-Payment">
          <p className="choose-text">Choose a Payment method</p>

          <div className="payment-options">
            <Link to="/paystack-payment">
              <img src={Paystack} alt="paystack" srcset="" />
            </Link>
            <Link to="/stripe-payment">
              <img src={Stripe} alt="stripe" srcset="" />
            </Link>
          </div>
        </div>
        <p className="payment-text">
          Send these informations so that we can start drafting your stock
          certificate for HospiYou,inc
        </p>
      </section>
    </div>
  );
};
export default Index;
