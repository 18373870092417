import React from "react";
import { Link } from "react-router-dom";
import howImage from "../Images/How.png";
import howImg1 from "../Images//How-1.png";
import howImg2 from "../Images/How-2.png";
import howImg3 from "../Images/How 3.png";
import "./how.css";

const index = () => {
  return (
    <div className="how">
      <div className="containe">
        <img src={howImage} width="500px" className="image" alt="" srcset="" />

        <div className="right-side">
          <div style={{ textAlign: "center" }} className="w-invest">
            <p className="s-text about-w" style={{ color: "green" }}>
              if you're wondering...
            </p>
            <p className="how-title">
              How your Investment will Make a Difference{" "}
            </p>
          </div>

          <div className="board">
            <div className="how-board">
              <img src={howImg1} width="50px" alt="" />
              <section>
                <p className="board-title">Fuel Innovation</p>
                <p className="text">
                  Your investment will fuel our ongoing research and development
                  effort, allowing us to continue innovating and expanding our
                  project
                </p>
              </section>
            </div>
            <div className="how-board">
              <img src={howImg2} width="50px" alt="" />
              <section>
                <p className="board-title">Expand Reach</p>
                <p className="text">
                  we will use he funds to expand our market reach, bringing
                  quality healthcare services to more people around the globe
                </p>
              </section>
            </div>
            <div className="how-board">
              <img src={howImg3} width="50px" alt="" />
              <section>
                <p className="board-title">Enhance Technology</p>
                <p className="text">
                  We will invest in enhancing our AI capabilities, ensuring that
                  our solutions remain cutting edge and effective
                </p>
              </section>
            </div>
          </div>

          <Link className="invest-how" to="/payment">
            Invest Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default index;
