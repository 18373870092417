// Success.js
import React from "react";

const Success = () => {
  return (
    <div>
      <h2>Payment Successful</h2>
      <p>Thank you for your payment.</p>
    </div>
  );
};

export default Success;
